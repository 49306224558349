// Colors
$light-text: rgb(33, 33, 33);
$light-main: #f3f4fd;
$light-contrast: $light-text;
$light-lowContrast: rgb(245, 245, 245);
$light-smallContrats: rgb(225, 225, 225);
$light-mediumContrast: rgba(196, 196, 196, 0.25);
$light-lowSmallContrast: rgb(235, 235, 235);
$light-textContrast: $light-main;
$light-textLow: rgb(150, 150, 150);
$light-green: rgb(0, 122, 100);
$light-ultra-green: rgb(230, 244, 254);
$light-red: rgb(232, 83, 83);
$light-ultra-red: rgb(254, 244, 230);
$light-blue: #7FA8C7;
$light-purple: #e377ec;
$light-gradient: linear-gradient(45deg, $light-blue, $light-purple);

$dark-text: rgb(250, 250, 250);
$dark-main: rgb(33, 33, 33);
$dark-contrast: $dark-text;
$dark-lowContrast: rgb(12, 12, 12);
$dark-mediumContrast: $dark-main;
$dark-textContrast: $dark-main;
$dark-textLow: rgb(150, 150, 150);
$dark-smallContrast: rgba(196, 196, 196, 0.25);
$dark-lowSmallContrast: rgb(49, 49, 49);
$dark-green: rgb(0, 122, 100);
$dark-ultra-green: rgb(230, 244, 254);
$dark-red: rgb(232, 83, 83);
$dark-ultra-red: rgb(254, 244, 230);
$dark-gradient: linear-gradient(45deg, $light-blue, $light-purple);
$dark-blue: $light-blue;
$padding-inline: 5%;

$xl-radius: 70px;
$big-radius: 20px;
$normal-radius: 10px;
$small-radius: 5px;
$xs-radius: 3px;

$big-title-size: 45px;
$title-size: 25px;
$text-size: 15px;
$medium-size: 20px;
$medium-big-size: 25px;
$small-size: 11px;

$fast-duration: 362ms;

// Responsive: Breakpoints
$big-bp: 1700px;
$medium-bp: 1500px;
$small-bp: 1000px;
$extraSmall-bp: 700px;
$mobile-bp: 570px;

@font-face {
    font-family: "Lobster";
    src: url('../assets/fonts/Lobster-Regular.ttf') format('truetype');
}

@font-face {
    font-family: "Roboto";
    src: url('../assets/fonts/Roboto-Regular.ttf') format('truetype');
}

@font-face {
    font-family: "Montserrat";
    src: url('../assets/fonts/Montserrat-Regular.ttf') format('truetype');
}

@font-face {
    font-family: "Montserrat-medium";
    src: url('../assets/fonts/Montserrat-Medium.ttf') format('truetype');
}

@font-face {
    font-family: "Montserrat-semi";
    src: url('../assets/fonts/Montserrat-SemiBold.ttf') format('truetype');
}

@font-face {
    font-family: "Montserrat-bold";
    src: url('../assets/fonts/Montserrat-Bold.ttf') format('truetype');
}

::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}
::-webkit-scrollbar-button {
    width: 0px;
    height: 0px;
}
::-webkit-scrollbar-thumb {
    background: #e1e1e1;
    border: 0px none #ffffff;
    border-radius: 50px;
}
::-webkit-scrollbar-track {
    background: transparent;
    border: 0px none transparent;
    border-radius: 20px;
    width: 0px;
}
::-webkit-scrollbar-corner {
    background: transparent;
}

body {
    margin: 0;
    padding: env(safe-area-inset);
    height: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;

    * {
        font-weight: normal;
        font-style: normal;
    }
D
    ::-webkit-scrollbar {
        width: 4px;
        height: 4px;
    }
    ::-webkit-scrollbar-button {
        width: 0px;
        height: 0px;
    }
    ::-webkit-scrollbar-thumb {
        background: #e1e1e1;
        border: 0px none #ffffff;
        border-radius: 50px;
    }
    ::-webkit-scrollbar-track {
        background: #f2f2f2;
        border: 0px none #ffffff;
        border-radius: 20px;
    }
    ::-webkit-scrollbar-corner {
        background: transparent;
    }
    
    * {
        font-family: "Montserrat";
        font-size: $text-size;
    }

    // Colors classes
    // Light theme
    .color-light-text {color: black}
    .bg-light-main {background-color: $light-main}
    .bg-light-main-transparent {background: #f3f4fde1;}
    .bg-light-component {background-color: white;}
    .bg-light-gradient {background: $light-gradient;}
    .bg-light-mobile-soft {background: #D9D9D9}
    .color-light-onSoft {color: #646464}
    .shadow-light {box-shadow: 2px 6px 15px rgba(0, 0, 0, 0.03);}
    .fill-light-contrast {fill: black}
    .fill-light-onContrast {fill: white}
    .fill-light-main {fill: $light-main}
    .fill-light-lowContrast {fill: rgb(207, 207, 207)}
    .color-light-onContrast {color: white}
    .border-light {border: 1px solid $light-main}
    .border-light-contrast {border: 1px solid black}
    .border-light-lowContrast {border: 1px solid rgb(207, 207, 207)}
    .border-light-left-onContrast {border-left: 1px solid white}
    .border-light-left-contrast {border-left: 1px solid rgb(148, 148, 148);}
    .border-light-left-green {border-left: 2px solid #48DC69;}
    .border-light-left-red {border-left: 2px solid #DC4869;}
    .border-light-main {border: 1px solid $light-main}
    .border-light-ultraLowContrast-bottom {border-bottom: 1px solid #DDDDDD;}
    .color-light-green {color: #48DC69}
    .color-light-red {color: #DC4869}
    .bg-light-blue {background: $light-blue}
    .border-bottom-light-onComponent {border-bottom: 1px solid $light-main}
    .bg-light-blue {background-color: $light-blue;}
    .color-light-white {color: white}
    .label-light {color: #7C7C7C}
    .label-light-onContrast {color: #c2c2c2}
    .bg-light-inactive {background-color: rgb(238, 238, 238);}
    .bg-light-nearContrast {background-color: $light-text;}
    .stroke-light-onContrast {stroke: white}
    .stroke-light-contrast {stroke: black}
    .hover-light-lowContrast {&:hover {background-color: $light-main}}
    .fill-light-red {fill: #DC4869}
    .bg-light-transparent {background-color: transparent;}
    .bg-light-info {background-color: #969696;}
    .color-light-info {color: #969696}

    .color-light-main {color: $light-main}
    .bg-light-contrast {background-color: black}
    .gradient-dark {background: $dark-gradient;}
    .color-light-contrast {color: $light-contrast}
    .bg-light-lowContrast {background-color: $light-lowContrast}
    .color-light-lowContrast {color: $light-lowContrast}
    .bg-light-mediumContrast {background-color: $light-mediumContrast}
    .color-light-mediumContrast {color: $light-mediumContrast}
    .color-light-textContrast {color: $light-textContrast}
    .bg-light-textLow {background-color: $light-textLow}
    .color-light-textLow {color: $light-textLow}
    .bg-light-smallContrast {background-color: $light-mediumContrast}
    .bg-light-lowSmallContrast {background-color: $light-lowSmallContrast}
    .bg-light-green {background-color: $light-green}
    .placeholder-light {&::placeholder {color: #7C7C7C}}
    .bg-light-red {background-color: $light-red}
    .bg-light-ultra-green {background-color: $light-ultra-green}
    .color-light-ultra-green {color: $light-ultra-green}
    .bg-light-ultra-red {background-color: $light-ultra-red}
    .color-light-ultra-red {color: $light-ultra-red}
    .fill-light-textLow {fill: $light-textLow}
    .border-bottom-light-text {border-bottom: 1px solid $light-text}
    .focus-border-bottom-light-green {&:focus {border-bottom: 2px solid $light-green}}
    
    // Dark theme
    .color-dark-text {color: white}
    .bg-dark-main {background-color: $dark-main}
    .bg-dark-main-transparent {background: rgba(33,33,33, 0.9);}
    .bg-dark-component {background-color: rgb(12,12,12);}
    .bg-dark-gradient {background: $dark-gradient;}
    .bg-dark-mobile-soft {background: black}
    .color-dark-onSoft {color: #969696}
    .shadow-dark {box-shadow: 2px 6px 15px rgba(0,0,0,0.1);}
    .fill-dark-contrast {fill: white}
    .fill-dark-onContrast {fill: black}
    .fill-dark-lowContrast {fill: rgb(150, 150, 150)}
    .color-dark-onContrast {color: black}
    .border-dark {border: 1px solid $dark-main}
    .border-dark-contrast {border: 1px solid white}
    .border-dark-lowContrast {border: 1px solid rgb(150, 150, 150)}
    .border-dark-main {border: 1px solid $dark-main}
    .color-dark-green {color: #48DC69}
    .color-dark-red {color: #DC4869}
    .border-dark-left-onContrast {border-left: 1px solid black}
    .border-dark-left-contrast {border-left: 1px solid rgb(148, 148, 148);}
    .border-dark-left-green {border-left: 1px solid #48DC69;}
    .border-dark-left-red {border-left: 1px solid #DC4869;}
    .border-dark-ultraLowContrast-bottom {border-bottom: 1px solid #3D3B3B;}
    .fill-dark-main {fill: $dark-main}
    .border-bottom-dark-onComponent {border-bottom: 1px solid $dark-main}
    .bg-dark-blue {background-color: $dark-blue;}
    .color-dark-white {color: white}
    .label-dark {color: #7C7C7C}
    .label-dark-onContrast {color: #5c5c5c}
    .bg-dark-inactive {background-color: rgb(238, 238, 238);}
    .bg-dark-nearContrast {background-color: $dark-text;}
    .stroke-dark-onContrast {stroke: black;}
    .stroke-dark-contrast {stroke: white}
    .hover-dark-lowContrast {&:hover {background-color: rgb(33, 33, 33)}}
    .fill-dark-red {fill: #DC4869}
    .bg-dark-transparent {background-color: transparent;}
    .bg-dark-info {background-color: #969696;}
    .color-dark-info {color: #969696}

    .color-dark-main {color: $dark-main}
    .bg-dark-contrast {background-color: $dark-contrast}
    .color-dark-contrast {color: $dark-contrast}
    .bg-dark-lowContrast {background-color: $dark-lowContrast}
    .color-dark-lowContrast {color: $dark-lowContrast}
    .bg-dark-mediumContrast {background-color: $dark-mediumContrast}
    .color-dark-mediumContrast {color: $dark-mediumContrast}
    .bg-dark-lowSmallContrast {background-color: $dark-lowSmallContrast}
    .color-dark-textContrast {color: $dark-textContrast}
    .bg-dark-textLow {background-color: $dark-textLow}
    .color-dark-textLow {color: $dark-textLow}
    .bg-dark-smallContrast {background-color: $dark-smallContrast}
    .bg-dark-green {background-color: $dark-green}
    .placeholder-dark {&::placeholder {color: #7C7C7C}}
    .bg-dark-red {background-color: $dark-red}
    .bg-dark-ultra-green {background-color: $dark-ultra-green}
    .color-dark-ultra-green {color: $dark-ultra-green}
    .bg-dark-ultra-red {background-color: $dark-ultra-red}
    .color-dark-ultra-red {color: $dark-ultra-red}
    .fill-dark-textLow {fill: $dark-textLow}
    .border-bottom-dark-text {border-bottom: 1px solid $dark-text}
    .focus-border-bottom-dark-green {&:focus {border-bottom: 2px solid $dark-green}}

    .bacground-image {
        background-size: cover;
        background-repeat: no-repeat;
    }

    // BG images classes
    .Animals {background-image: url('https://ik.imagekit.io/hhsvlzkzs/Topics/Animals.jpg');}
    .Art {background-image: url('https://ik.imagekit.io/hhsvlzkzs/Topics/Art.jpg');}
    .Body {background-image: url('https://ik.imagekit.io/hhsvlzkzs/Topics/Body.jpg');}
    .Business {background-image: url('https://ik.imagekit.io/hhsvlzkzs/Topics/Business.jpg');}
    .Cooking {background-image: url('https://ik.imagekit.io/hhsvlzkzs/Topics/Cooking.jpg');}
    .Cryptocurrencies {background-image: url('https://ik.imagekit.io/hhsvlzkzs/Topics/Cryptocurrencies.jpg');}
    .Culture {background-image: url('https://ik.imagekit.io/hhsvlzkzs/Topics/Culture.jpg');}
    .Design {background-image: url('https://ik.imagekit.io/hhsvlzkzs/Topics/Design.jpg');}
    .Ecology {background-image: url('https://ik.imagekit.io/hhsvlzkzs/Topics/Ecology.jpg');}
    .Food {background-image: url('https://ik.imagekit.io/hhsvlzkzs/Topics/Food.jpg')}
    .Gaming {background-image: url('https://ik.imagekit.io/hhsvlzkzs/Topics/Gaming.jpg');}
    .Health {background-image: url('https://ik.imagekit.io/hhsvlzkzs/Topics/Health.jpg');}
    .Healthcare {background-image: url('https://ik.imagekit.io/hhsvlzkzs/Topics/Healthcare.jpg');}
    .History {background-image: url('https://ik.imagekit.io/hhsvlzkzs/Topics/History.jpg');}
    .Investing {background-image: url('https://ik.imagekit.io/hhsvlzkzs/Topics/Investing.jpg');}
    .Journalism {background-image: url('https://ik.imagekit.io/hhsvlzkzs/Topics/Journalism.jpg');}
    .Legal {background-image: url('https://ik.imagekit.io/hhsvlzkzs/Topics/Legal.jpg');}
    .Literature {background-image: url('https://ik.imagekit.io/hhsvlzkzs/Topics/Literature.jpg');}
    .Marketing {background-image: url('https://ik.imagekit.io/hhsvlzkzs/Topics/Marketing.jpg');}
    .Money {background-image: url('https://ik.imagekit.io/hhsvlzkzs/Topics/Money.jpg');}
    .Nature {background-image: url('https://ik.imagekit.io/hhsvlzkzs/Topics/Nature.jpg');}
    .Nutrition {background-image: url('https://ik.imagekit.io/hhsvlzkzs/Topics/Nutrition.jpg');}
    .Philosophy {background-image: url('https://ik.imagekit.io/hhsvlzkzs/Topics/Philosophy.jpg');}
    .Physics {background-image: url('https://ik.imagekit.io/hhsvlzkzs/Topics/Physics.jpg');}
    .Politics {background-image: url('https://ik.imagekit.io/hhsvlzkzs/Topics/Politics.jpg');}
    .Psychology {background-image: url('https://ik.imagekit.io/hhsvlzkzs/Topics/Psychology.jpg');}
    .Religion {background-image: url('https://ik.imagekit.io/hhsvlzkzs/Topics/Religion.jpg');}
    .Science {background-image: url('https://ik.imagekit.io/hhsvlzkzs/Topics/Science.jpg');}
    .Sex {background-image: url('https://ik.imagekit.io/hhsvlzkzs/Topics/Sex.jpg');}
    .Society {background-image: url('https://ik.imagekit.io/hhsvlzkzs/Topics/Society.jpg');}
    .Space {background-image: url('https://ik.imagekit.io/hhsvlzkzs/Topics/Space.jpg');}
    .Technologies {background-image: url('https://ik.imagekit.io/hhsvlzkzs/Topics/Technologies.jpg');}
    .Trading {background-image: url('https://ik.imagekit.io/hhsvlzkzs/Topics/Trading.jpg');}
    .Travel {background-image: url('https://ik.imagekit.io/hhsvlzkzs/Topics/Travel.jpg');}
    .Work {background-image: url('https://ik.imagekit.io/hhsvlzkzs/Topics/Work.jpg');}

    .logo {
        font-family: "Lobster";
        text-decoration: none;
        font-size: $title-size;

        .gab-link {
            font-size: 12px;
            color: #969696;
            margin: 0px;
            text-decoration: none;
        }

        @media screen and (max-width: $small-bp) {
            font-size: 15px;
            margin-left: 10px;
        }
    }

    a:focus {
        outline: none !important;
    }

    .loading-wrapper {
        width: 95%;
        max-width: 300px;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;

        .loading-bar {
            border-radius: 5px;
            height: 5px;
            width: 100%;

            .loading {
                width: 20%;
                height: 100%;
                border-radius: 5px;
                animation: loading 1000ms cubic-bezier(0.075, 0.82, 0.165, 1) infinite;
            }
        }
    }

    .title {
        font-size: $title-size;
        font-family: "Montserrat-bold";
        font-weight: 500;
        text-align: center;
    }

    .debate-title {
        font-size: $big-title-size;
        font-family: "Montserrat-bold";
        text-align: center;
        max-width: 65%;

        @media screen and (max-width: $small-bp) {
            max-width: 75%;
        }

        @media screen and (max-width: $extraSmall-bp) {
            max-width: 85%;
        }

        @media screen and (max-width: $mobile-bp) {
            max-width: 90%;
            font-size: 30px;
        }
    }

    .input:not(#checkbox, textarea) {
        padding-left: 10px;
        height: 38px;
        width: 250px;
        margin: 5px 0px;
        font-family: "Montserrat-medium";
        border-radius: $normal-radius;
        background-color: transparent;

        &:focus {
            outline: 0px solid transparent;
        }
    }

    textarea {
        outline: 0px solid transparent;
        border: transparent;

        &:focus {
            outline: 0px solid transparent;
        }
    }

    .big {
        width: 350px;
        max-width: 400px;
        max-height: 70px;
    }

    .small-ft {
        font-size: $small-size;
    }

    .medium-ft {
        font-size: $medium-size;
    }

    .medium-big-ft {
        font-size: $medium-big-size;
    }

    .big-ft {
        font-size: $title-size;
    }

    .icon-input {
        display: flex;
        align-items: center;
        width: 260px;
        border-radius: $xs-radius;
        height: 40px;
        margin: 5px 0px;

        span {
            margin: 0px 0px;
            margin-left: 10px;
            font-family: "Montserrat-bold";
            font-size: $text-size;
        }

        .input {
            width: 210px;
        }
    }

    .bold {
        font-family: "Montserrat-bold";
    }

    .semi-bold {
        font-family: "Montserrat-semi";
    }

    .medium {
        font-family: "Montserrat-medium";
    }

    .modal-full {
        height: 100vh;
        width: 100%;
        position: fixed;
        top: 0px;
        left: 0px;
        z-index: 501;
        background-color: rgba(0,0,0,0.62);
        display: flex;
        justify-content: center;
        align-items: center;

        .modal-wrapper {
            height: max-content;
            max-height: 95%;
            max-width: 99%;
            border-radius: 10px;
            display: flex;
            flex-direction: column;
            overflow-y: scroll;
            -ms-overflow-style: none; /* for Internet Explorer, Edge */
            scrollbar-width: none; /* for Firefox */

            &::-webkit-scrollbar {
                display: none;
            }

            .modal-close-wrapper {
                width: calc(100% - 20px);
                height: 20px;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                position: sticky;
                top: 0;
                padding: 20px 10px;

                .modal-close {

                    &:hover {
                        cursor: pointer;
                    }

                    .close-bar {
                        width: 20px;
                        height: 3px;
                        border-radius: 3px;
                        background-color: rgb(150, 150, 150);
                    }

                    .close-bar:nth-last-child(1) {
                        transform: rotate(45deg);
                        margin-top: -3px;
                    }

                    .close-bar:nth-last-child(2) {
                        transform: rotate(-45deg);
                    }
                }
            }

            .modal-content {
                padding: 10px 25px;

                .content {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-evenly;

                    .modal-title {
                        margin-top: 5px;
                        margin-bottom: 30px;
                        font-size: 20px;
                        font-family: "Montserrat-Bold";
                    }
    
                    .modal-textarea {
                        display: block;
                        width: 100%;
                        min-width: 400px;
                        height: 100px;
                        min-height: 100px;
                        resize: vertical;
                        margin-bottom: 30px;
                    }

                    input {
                        width: 100%;
                    }

                    label {
                        margin-bottom: 3px;
                    }

                    textarea {
                        height: 90px;
                    }

                    .btn {
                        width: max-content;
                        text-align: center;
                        margin-right: 0px;
                        margin-top: 30px;
                        align-self: flex-end;
                    }

                    .stats-numbers {

                        .stats-numbers-title {
                            font-size: 30px;
                            font-family: "Montserrat-Bold";
                        }
                        
                        .stats-data {
                            display: flex;
                            align-items: center;
                            justify-content: space-evenly;
                            gap: 20px;
                            margin: 30px 0;
                            
                            @media screen and (max-width: $extraSmall-bp) {
                                flex-direction: column;
                                align-items: flex-start;
                            }

                            .stats-number {
                                display: flex;
                                flex-direction: column;
                                align-items: flex-start;

                                .number-wrapper {
                                    display: flex;
                                    align-items: baseline;

                                    .number {
                                        font-family: "Montserrat-Bold";
                                        font-size: 35px;
                                        margin: 0;
                                        padding-right: 5px;
                                    }
                                }

                                .stat-info {
                                    margin-top: 0;
                                }
                            }

                            #full-chart {
                                width: 100%;
                                aspect-ratio: 1.618;
                            }
                        }
                    }
                }

                #delete-account-modal {
                    
                    h2 {
                        font-family: "Montserrat-Bold";
                        font-size: 20px;
                    }

                    input {
                        margin-top: 10px;
                        width: 95% !important;
                    }

                    #inputs-inline {
                        margin-top: 50px;
                        display: flex;
                        width: 100%;
                        justify-content: flex-end;
                        gap: 15px;

                        button {
                            padding: 10px;

                            &:hover {
                                cursor: pointer;
                            }
                        }
                    }
                }
            }
        }
    }

    .btn-actions-floating {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 3px;
        padding: 13px 7.5px;
        border-radius: 15px;

        &:hover {
            cursor: pointer;
        }

        span {
            width: 3px;
            height: 3px;
            border-radius: 1.5px;
        }
    }

    .btn {
        padding: 10px 20px;
        margin: 10px 0px;
        height: 35px;
        display: flex;
        align-items: center;
        border-radius: $normal-radius;
        font-family: "Montserrat-Bold";
        transition: transform 618ms cubic-bezier(0.075, 0.82, 0.165, 1);
        border: none;

        &:hover {
            cursor: pointer;
            transform: scale(0.9);
        }

        a {
            text-decoration: none;
            padding: 5px 0;
            font-family: "Montserrat-Bold";
        }
    }

    .icon-btn {
        transition: transform 620ms cubic-bezier(.54,.41,.01,.92);

        &:hover {
            transform: scale(0.95);
            cursor: pointer;
        }
    }

    .medium-btn {
        height: 30px;
        border-radius: 16px;
        font-size: $text-size;
        padding: 10px;
    }

    .clickable:hover {
        cursor: pointer;
        //text-decoration: underline;
    }

    .no-margin {
        margin: 0px;
    }

    .info {
        padding: 5px;
        font-family: "Montserrat-bold";
        text-align: center;
        padding-inline: 10px;
    }

    .no-bg {
        background-color: rgba(0, 0, 0, 0);
        padding: 10px 0px;
        border: none;

        &:hover {
            cursor: pointer;
            opacity: 0.9;
        }
    }

    .underline {
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }
    }

    .horizontal-flex {
        display: flex;
        align-items: center;
        gap: 5px;
    }

    .fixed {
        position: fixed;
    }

    .dialog {
        display: none;
        position: fixed;
        z-index: 1000;
        top: 5px;
        left: 50%;
        transform: translateX(-50%);
        border-radius: $small-radius;
        padding: 10px;
        text-align: center;

        p {
            margin: 0px;
        }
    }

    #log {
        display: none;
        position: fixed;
        z-index: 1000;
        top: 10px;
        left: 50%;
        transform: translate(-50%);
        padding: 10px;
        text-align: center;
        border-radius: 5px;
    }

    #confirmation {
        position: fixed;
        z-index: 1000;
        top: 10px;
        left: 50%;
        transform: translate(-50%);
        padding: 10px;
        text-align: center;
        border-radius: 5px;

        p {
            font-family: "Montserrat-Bold";
            font-size: 15px;
            color: inherit;
        }
    }

    #theme-switch-container {
        width: 50px;
        height: 30px;
        border-radius: 15px;
        display: flex;
        align-items: center;
        position: fixed;
        z-index: 2;
        bottom: 40px;
        right: 40px;

        &:hover {
            cursor: pointer;
        }

        #theme-switch {
            height: 24px;
            width: 24px;
            border-radius: 12px;
            border: none;

            &:hover {
                cursor: pointer;
            }
        }

        @media screen and (max-width: $small-bp) {
            bottom: 20px;
            right: 20px;
        }
    }

    .info {
        background-color: aquamarine;
        color: rgb(33, 33, 33);
    }

    .success {
        background-color: $dark-green;
        color: white;
    }

    .warning {
        background-color: rgb(240, 182, 23);
        color: white;
    }

    .error {
        background-color: $dark-red;
        color: white;
    }

    .box-title {
        font-family: "Montserrat-bold";
        margin: 1px;
    }

    .box-description {
        font-size: $small-size;
        margin: 1px;
    }

    .bottom-load-bar-wrapper {
        position: fixed;
        bottom: 0px;
        width: 100%;
        height: 15px;

        .bottom-load-bar {
            width: 25%;
            height: 100%;
        }
    }

    .profile-pic-wrapper {
        width: 30px;
        height: 30px;
        border-radius: 15px;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .home-side-component-wrapper {
        width: 100%;
        border-radius: $normal-radius;
        margin-bottom: 20px;
        
        .home-side-component {
            width: calc(100% - 20px);
            padding: 10px;
            color: inherit;

            .home-side-title {
                font-family: "Montserrat-Bold";
                font-size: 20px;
                text-align: center;
                text-justify: center;
                color: inherit;
            }

            .trending-topic {
                margin: 20px 0;
                text-decoration: none;

                h3 {
                    font-family: "Montserrat-Bold";
                    font-size: 15px;
                    margin-bottom: 0px;
                }

                p {
                    font-size: 11px;
                    margin-top: 0px;
                }
            }

            .home-userStat-wrapper {
                display: flex;
                align-items: baseline;
                justify-content: flex-start;
                gap: 5px;
                margin-left: 10px;

                h3 {
                    margin: 10px 0;
                    font-size: 25px;
                    font-family: "Montserrat-Bold";
                }

                p {
                    margin: 10px 0;
                }
            }

            .home-private-debate {
                width: calc(100% - 20px);
                padding: 10px;
                text-decoration: none;

                &:nth-last-child(1) {
                    border-bottom: 0px solid transparent;
                }

                h3 {
                    font-family: "Montserrat-Bold";
                }

                .home-private-debate-stats {
                    display: flex;
                    justify-content: space-evenly;
                    align-items: center;

                    span {
                        display: flex;
                        align-items: center;

                        svg {
                            width: 20px;
                            margin-right: 10px;
                        }
                    }
                }
            }

            .home-peopleToFollow-wrapper {
                width: 100%;

                .home-peopleToFollow {
                    width: 95%;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 15px;

                    .home-peopleToFollow-domain {
                        margin-bottom: 0px;
                    }

                    .home-peopleToFollow-uid {
                        font-family: "Montserrat-Bold";
                        font-size: 15px;
                        margin-top: 0px;
                    }

                    button {
                        border-radius: 50px;
                    }
                }
            }

            .vote-btns-wrapper {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 15px;
                height: max-content;

                p {
                    font-family: "Montserrat-Bold";
                    font-size: 16px;
                    text-align: center;

                    &:hover {
                        cursor: pointer;
                    }
                }

                div {
                    height: 50px;
                    border-top: 0px solid transparent;
                    border-bottom: 0px solid transparent;
                    border-left: 0px solid transparent;
                }
            }
        }
    }

    .floating-menu-wrapper {
        top: 0;
        left: 0;
        opacity: 0;
        position: fixed;
        z-index: 1000;
        width: 100vw;
        height: 100vh;

        .floating-menu {
            position: fixed;
            z-index: 1000;
            border-radius: $normal-radius;

            .floating-menu-content {
                padding: 10px;

                .floating-menu-home-debate {
                    height: 100%;
                    border-radius: $normal-radius;
                    
                    .floating-menu-home-action {
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        gap: 10px;
                        padding: 0 10px;
                        min-height: 50px;
    
                        &:hover {
                            cursor: pointer;
                            background-color: rgba(0, 0, 0, 0.01);
                        }
                    }

                    a {
                        text-decoration: none;
                    }
                }
    
                .floating-menu-svg-text {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    min-width: max-content;
                    gap: 10px;
                    padding: 0 10px;
                    border-radius: $normal-radius;
    
                    &:hover {
                        cursor: pointer;
                        background-color: rgba(0, 0, 0, 0.01);
                    }
    
                    p {
                        min-width: max-content;
                        padding: 3px;
                        font-family: "Montserrat-medium";
                    }
                }
            }
        }
    }

    #in-dev-wrapper {
        display: flex;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100vw;
        position: fixed;
        align-items: center;
        justify-content: center;

        #in-dev {
            max-width: 80%;
            text-align: center;
            font-size: 4rem;
            font-family: "Montserrat-Bold";

            @media screen and (max-width: $small-bp) {
                font-size: 30px;
            }
        }
    }

    header {
        width: 100%;
        position: fixed;
        z-index: 500;
        backdrop-filter: blur(3px);
        
        #header {
            padding: 20px $padding-inline;
            display: flex;
            justify-content: space-between;
            align-items: center;

            @media screen and (max-width: $small-bp) {
                padding: 17px 10px;
            }

            p {
                margin: 0;
            }

            .search-bar {
                width: min(40%, 700px);
                padding-left: 5px;
                position: absolute;
                left: 50%;
                transform: translateX(-50%);

                label {
                    position: relative;
                }
                
                label:before {
                    content: "";
                    position: absolute;
                    left: 10px;
                    top: 0;
                    bottom: 0;
                    width: 20px;
                    background: url("../assets/icons/search.svg") center / contain no-repeat;
                }

                #input-wrapper {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: $normal-radius;
                    height: 45px;

                    @media screen and (max-width: $small-bp) {
                        height: 35px;
                    }

                    svg {
                        padding-inline: 5px;
                    }

                    input {
                        border-radius: $normal-radius;
                        padding-left: 5px;
                        border: none;
                        width: 100%;
                        font-family: "Montserrat-medium";
    
                        &:focus {
                            outline: 0px solid transparent;
                        }
    
                        &::placeholder {
                            color: inherit;
                        }

                        ::-webkit-search-cancel-button {
                            background-color: $light-red;
                        }
                    }
                }

                @media screen and (max-width: $small-bp) {
                    width: 35%;
                    left: 35%;
                    transform: translateX(-65%);
                }

                @media screen and (max-width: $extraSmall-bp) {
                    max-width: 20%;
                    min-width: 120px;
                    left: 170px;
                    //transform: translateX(-150px);
                }
            }

            .research-results-wrapper {
                position: fixed;
                display: flex;
                justify-content: center;
                top: 0px;
                left: 0px;
                width: 100vw;
                height: 100vh;

                .research-results {
                    position: fixed;
                    border-radius: $normal-radius;
                    max-height: 80vh;
                    overflow-y: scroll;
                    -ms-overflow-style: none; /* for Internet Explorer, Edge */
                    scrollbar-width: none; /* for Firefox */

                    &::-webkit-scrollbar {
                        display: none;
                    }

                    .results {
                        width: calc(100% - 20px);
                        padding: 10px;
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;

                        .results-content {
                            margin-bottom: 10px;
                            margin-left: 10px;

                            h1 {
                                font-family: "Montserrat-Bold";
                                font-size: 15px;
                                //text-decoration: underline;
                                text-underline-offset: 3px;
                                margin-left: -10px;
                            }

                            a {
                                text-decoration: none;

                                .search-debate-wrapper {
                                    padding: 10px;
                                    border-radius: $normal-radius;
                                    margin: 20px 0px;
                                    margin-right: 10px;
                                    display: block;

                                    p {
                                        font-family: "Montserrat-medium";
                                    }

                                    .result-info {
                                        display: flex;
                                        justify-content: flex-start;
                                        align-items: center;
                                        gap: 20px;

                                        span {
                                            display: flex;
                                            justify-content: center;
                                            align-items: center;
                                            gap: 5px;
                                            padding: 5px 0;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .account-log-sign {
                display: flex;
                align-items: center;
                gap: 20px;

                #login {

                    #button-login {
                        font-family: 'Montserrat-medium';
                        text-decoration: 'none';
                        background-color: transparent;
                        font-size: $text-size;
                        border: none;

                        &:hover {
                            cursor: pointer;
                        }
                    }
                }

                button {

                    #button-signup {
                        text-decoration: none;
                        font-family: "Montserrat-medium";
                    }
                }

                @media screen and (max-width: $extraSmall-bp) {

                    #button-login {
                        text-decoration: none;
                        font-size: 13px;
                        margin-block: 5px;
                    }

                    button {
                        display: none;
                    }
                }
            }

            #user-wrapper {
                width: 50px;
                height: 50px;
                margin: 0;
                padding: 0;
                border-radius: 25px;
                overflow: hidden;
                max-width: min-content;

                @media screen and (max-width: $mobile-bp) {
                    height: 30px;
                }

                &:hover {
                    cursor: pointer;
                }

                img {
                    border-radius: 25px;
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }

    .full-page-form {
        width: 100%;
        display: flex;
        min-height: 100vh;
        justify-content: center;
        align-items: center;

        #fixed-logo-wrapper {
            top: 3vh;
            left: 3vw;
        }

        .form-wrapper {
            width: 95%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            
            .form {
                padding: 50px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-evenly;
                text-align: center;
                border-radius: $normal-radius;

                p:not(.box-title, .box-description) {
                    font-size: $medium-size;

                    @media screen and (max-width: $small-bp) {
                        text-align: left;
                        font-size: 15px;
                    }
                }

                .btn {
                    width: 250px;
                    height: 45px;

                    div {
                        font-family: "Montserrat-Bold";
                    }
                }

                .title {
                    font-size: 40px;
                    font-family: "Montserrat-Bold";
                    margin: 0px auto;

                    @media screen and (max-width: $small-bp) {
                        text-align: left;
                        font-size: 25px;
                    }
                }

                .inputs-inline {
                    height: 100%;
                    width: 100%;
                    display: flex;
                    justify-content: space-evenly;
                    align-items: center;
                    gap: 30px;

                    .label-input-wrapper {
                        margin: 15px 0px;
                        max-width: 260px;
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        text-align: left;
                    
                        label {
                            display: block;
                            margin-left: 2px;
                            text-align: left;
                        }

                        input, select {
                            display: block;
                        }
                    }

                    .form-back {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        border-radius: $normal-radius;
                        height: 45px;
                        padding: 0 10px;
                        font-family: "Montserrat-medium";

                        &:hover {
                            cursor: pointer;
                        }

                        a {
                            text-decoration: none;
                        }
                    }

                    a {
                        text-decoration: none;
                    }
                }

                a {
                    font-family: "Montserrat-Bold";
                }

                form {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: space-evenly;
                    margin: 50px 0;

                    @media screen and (max-width: $small-bp) {
                        align-items: flex-start;
                    }

                    .inputs-column {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        gap: 20px;
                    }

                    label {
                        font-family: "Montserrat-medium";
                    }

                    .switch-btn {
                        width: 90px;
                        height: 40px;
                        border-radius: 20px;
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;

                        div {
                            height: calc(100% - 8px);
                            aspect-ratio: 1;
                            border-radius: 20px;
                            margin: 0 4px;
                        }
                    }

                    textarea {
                        width: 90%;
                        max-width: 400px;
                        margin-bottom: 5vh;
                        font-family: "Montserrat-medium";
                        background-color: "transparent";
                        resize: none;
                        text-align: center;
                        height: 25px;
                    }

                    input {
                        background-color: transparent;
                        max-width: 240px;
                        border-radius: $normal-radius;
                    }

                    select {
                        background-color: transparent;
                        outline: 0px solid transparent;
                        height: 40px;
                        width: 260px;
                        font-family: "Montserrat-medium";
                        border-radius: $normal-radius;

                        &:focus {
                            outline: 0px solid transparent;
                        }
                    }

                    #debate-type-buttons {
                        display: flex;
                        justify-content: space-between;
                        width: 90%;
                        max-width: 500px;
                        gap: 50px;

                        div {
                            height: 110px;
                            width: 160px;
                            border-radius: $xs-radius;
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: space-evenly;

                            &:hover {
                                cursor: pointer;
                            }

                            
                        }
                    }

                    .form-content {
                        display: flex;
                        flex-direction: column;
                        justify-content: space-evenly;
                        align-items: flex-start;
                        width: 100%;

                        p {
                            margin: 0 0;
                            padding: 0 0;
                        }

                        textarea {
                            border-radius: $normal-radius;
                            margin-top: 20px;
                            width: 100%;
                            height: 60px;
                            padding: 10px;
                            text-align: left;
                        }

                        #center {
                            width: 100%;
                            display: flex;
                            flex-direction: column;
                            justify-content: space-evenly;
                            align-items: center;

                            #debate-type-buttons {
                                margin: 30px 0;
                            }

                            #multi-select-friends {
                                max-width: 400px;
                                font-size: $text-size;
                                margin-bottom: 30px;
                            }
                        }
                    }

                    .set-profile-pic {
                        width: 100px;
                        height: 100px;
                        border-radius: 50px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        overflow: hidden;

                        svg {
                            width: 60%;
                            aspect-ratio: 1;
                            stroke-width: 1px;
                        }

                        &:hover {
                            cursor: pointer;
                        }

                        input {
                            display: none;
                        }

                        img {
                            width: 100%;
                            height: auto;
                        }
                    }
                }

                #code-verif-wrapper {
                    width: 100%;
                    display: flex;
                    justify-content: space-evenly;
                    align-items: center;
                    margin-bottom: 10vh;

                    .code-box {
                        height: 53px;
                        width: 50px;
                        border-radius: $big-radius;
                        font-size: $medium-size;
                        text-align: center;
                        font-family: "Montserrat-medium";
                        outline: 0px solid transparent;
                        border: transparent;

                        &:focus {
                            outline: 0px solid transparent;
                        }
                    }
                }

                .dynamic-rules {
                    height: 100%;
                    margin: 0px 20px;
                    margin-top: -10px;
                    display: flex;
                    flex-direction: column;

                    p {
                        margin-bottom: 0px;
                        font-size: $text-size;
                        text-align: right;
                    }

                    @media screen and (max-width: $small-bp) {
                        margin: 0 5px;
                        height: max-content;

                        p {
                            text-align: left;
                            font-size: 12px;
                            font-family: "Montserrat-semi";
                        }
                    }
                }

                .center {
                    margin: auto;

                    p {
                        text-align: center;
                    }
                }
                
                @media screen and (max-width: $small-bp) {
                    align-items: flex-start;
                    padding: 20px;

                    .inputs-inline {
                        flex-direction: column;
                        align-items: flex-start;
                        gap: 15px;
                    }
                }
            }
        }
    }

    .main-wrapper {
        width: 100%;
        min-height: 100vh;
        display: flex;
        justify-content: center;
        
        .main {
            width: 100% - ($padding-inline * 2);
            margin-top: 10vh;

            @media screen and (max-width: $small-bp) {
                width: 96%;

                #private-debates, #user-stats {
                    border-radius: 0;
                    margin: 0;
                }
            }

            @media screen and (max-width: $mobile-bp) {
                width: 100%;
                margin-top: 20px;
            }

            #home-wrapper {
                display: flex;
                justify-content: center;

                #home {
                    justify-self: center;
                    margin-top: 30px;
                    width: 100%;
                    max-width: 1500px;
    
                    .shadow {
                        height: 35px;
                        border-radius: $small-radius;
                        opacity: 1;
                        animation: shadow-animation 2s ease-in-out infinite;
                    }
    
                    .home-caroussel-wrapper {
                        width: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin-bottom: 60px;
    
                        .home-caroussel {
                            width: 100%;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            gap: 50px;
                        
                            .home-caroussel-part-wrapper {
                                width: min(260px, 15vw);
                                aspect-ratio: 1.618;
                                border-radius: $normal-radius;
                                text-decoration: none;
                                overflow: hidden;
    
                                .home-caroussel-part {
                                    border-radius: $normal-radius;
                                    width: 100%;
                                    height: 100%;
                                    display: flex;
                                    justify-content: space-between;
                                    align-items: flex-end;
                                    background-size: 100%;
                                    background-repeat: no-repeat;
                                    background-position: center;
                                    transition: background-size 618ms cubic-bezier(0.075, 0.82, 0.165, 1);
    
                                    &:hover {
                                        background-size: 150%;
                                    }
    
                                    .home-caroussel-info {
                                        height: 95%;
                                        width: 95%;
                                        padding: 15px;
                                        display: flex;
                                        justify-content: space-between;
                                        align-items: flex-end;
        
                                        .home-caroussel-title {
                                            width: 100%;
                                            font-size: 12px;
                                            font-family: "Montserrat-Bold";
                                        }
    
                                        .loaded {
                                            background-color: rgba(0, 0, 0, 0.637);
                                            padding: 5px;
                                            border-radius: 3px;
                                        }
                                    }
                                }
                            }
                        }
                    }
    
                    #home-body {
                        width: 100%;
                        height: auto;
                        display: flex;
                        align-items: flex-start;
                        justify-content: center;
                        gap: 50px;
    
                        #home-left-side, #home-right-side {
                            width: 20vw;
                            display: flex;
                            flex-direction: column;
    
                            .home-side-shadow-wrapper {
                                width: 100%;
                                height: 150px;
                                border-radius: $normal-radius;
                                margin-bottom: 20px;
    
                                .home-side-shadow {
                                    width: 100%;
                                    height: 100%;
    
                                    .shadow {
                                        margin-top: 10px;
                                        margin-left: 10px;
                                        width: 62%;
                                    }
                                }
                            }
                        }
    
                        #home-main {
                            max-width: 35vw;
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            gap: 20px;
                            margin-bottom: 200px;

                            @media screen and (max-width: $mobile-bp) {
                                align-items: flex-start;
                                gap: 0;
                            }

                            #home-welcome {
                                display: none;

                                @media screen and (max-width: $mobile-bp) {
                                    display: block;
                                    font-size: 22px;
                                    font-family: "Montserrat-Bold";
                                    margin-top: 20px;
                                    margin-left: 10px;
                                }
                            }
    
                            .btn {
                                text-decoration: none;
                                margin-top: 0px;
                            }
    
                            #open-debate-btn {
                                width: 100%;
                                text-align: center;
                                display: flex;
                                justify-content: center;
                                padding: 5px 0;
                                border: none;
    
                                p {
                                    font-family: "Montserrat-Bold";
                                }
                            }
    
                            #open-debate-floating {
                                display: none;
                            }
    
                            .home-debate-wrapper {
                                display: block;
                                width: calc(35vw - 20px);
                                height: auto;
                                overflow-y: visible;
                                padding: 10px;
                                border-radius: $normal-radius;

                                @media screen and (max-width: $mobile-bp) {
                                    border-radius: 0;

                                    &:not(.mobile-shadow) {
                                        background-color: transparent;
                                        box-shadow: none;
                                        border-left: none;
                                        border-right: none;
                                        border-bottom: none;
                                        padding: 20px 10px;
                                    }
                                }
            
                                .home-debate {
                                    width: 100%;
                                    height: 100%;
                                    display: flex;
                                    flex-direction: column;
                                    justify-content: space-between;
                                    overflow-x: hidden;
    
                                    button {
    
                                        div {
                                            width: 100%;
                                            height: 100%;
                                            display: flex;
                                            justify-content: center;
                                            align-items: center;
                                            max-height: 30px;
                                        }
    
                                        &:hover {
                                            cursor: pointer;
                                        }
                                    }
            
                                    .home-debate-top {
                                        display: flex;
                                        flex-direction: column;
                                        justify-content: flex-start;
                                        height: auto;
                                        gap: 10px;
    
                                        h2 {
                                            font-size: 20px;
                                            max-width: 90%;
                                            margin: 0;
                                        }
            
                                        .home-debate-title {
                                            display: flex;
                                            align-items: flex-start;
                                            justify-content: space-between;
            
                                            .shadow {
                                                width: calc(100% - 250px);
                                            }
            
                                            .shadow:nth-last-child(1) {
                                                width: 35px;
                                            }
    
                                            h2 {
                                                font-family: "Montserrat-Bold";
                                                max-width: calc(100% - 50px);
    
                                                @media screen and (max-width: $extraSmall-bp) {
                                                    font-size: 17px;
                                                }
                                            }
    
                                            button {
                                                background-color: transparent;
                                                border: none;
                                            }
                                        }
            
                                        .shadow:nth-last-child(1) {
                                            width: 70px;
                                        }
    
                                        .home-debate-actions-wrapper {
                                            width: 100%;
                                            display: flex;
                                            justify-content: flex-start;
                                            align-items: center;
                                            gap: 15px;
                                                
                                            .home-debate-arrows-wrapper {
                                                height: 30px;
                                                display: flex;
                                                align-items: center;
                                                border-radius: $normal-radius;
    
                                                .home-debate-arrows {
                                                    height: 25px;
                                                    display: flex;
                                                    align-items: center;
    
                                                    button {
                                                        border-radius: $normal-radius 0 0 $normal-radius;
                                                        border-top: 0px solid transparent;
                                                    }
    
                                                    button:nth-last-child(1) {
                                                        border-top: 0px solid transparent;
                                                        border-bottom: 0px solid transparent;
                                                        border-right: 0px solid transparent;
                                                        border-radius: 0 $normal-radius $normal-radius 0;
                                                    }
    
                                                    button:nth-child(1) {
                                                        border: 0px solid transparent;
                                                    }
    
                                                    svg {
                                                        transition: transform 618ms cubic-bezier(0.075, 0.82, 0.165, 1);
    
                                                        &:hover {
                                                            transform: scale(1.2);
                                                        }
                                                    }
                                                }
                                            }
    
                                            .home-debate-arg-type {
                                                height: 30px;
                                                padding: 0 5px;
                                                border-radius: $normal-radius;
    
                                                button {
                                                    height: 100%;
                                                    width: 100%;
                                                    border-radius: $normal-radius;
                                                    border: 0px solid transparent;
                                                    background-color: transparent;
                                                
                                                    div {
                                                        font-size: 12px;
                                                    }
                                                }
                                            }
                                        }
                                    }
            
                                    .home-debate-middle {
                                        padding-left: 10px;
                                        margin: 15px 0;
                                        width: 200%;
                                        display: flex;
    
                                        div {
                                            width: 50%;
                                            text-align: justify;
    
                                            @media screen and (max-width: $extraSmall-bp) {
                                                width: 49%;
                                            }
    
                                            .home-debate-score-author-type {
                                                display: flex;
                                                justify-content: flex-start;
                                                align-items: flex-end;
    
                                                h3 {
                                                    font-size: 40px;
                                                    font-family: "Montserrat-Bold";
                                                    margin-bottom: 0px;
                                                    margin-top: 20px;
                                                    padding-bottom: 0px;
                                                }
    
                                                .home-debate-author-type {
                                                    height: 100%;
                                                    display: flex;
                                                    flex-direction: column;
                                                    justify-content: flex-end;
                                                    width: max-content;
                                                    padding-left: 10px;
    
                                                    p {
                                                        margin: 0px;
                                                        font-size: 13px;
                                                    }
    
                                                    h4 {
                                                        font-size: 13px;
                                                        margin-bottom: 7px;
                                                        font-family: "Montserrat-Bold";
                                                        padding: 0px;
                                                        margin-top: 0px;
                                                        min-width: max-content;
                                                    }
                                                }
                                            }
    
                                            p {
                                                max-width: 98%;
                                                margin-bottom: 20px;
                                            }
                                        }
            
                                        .shadow {
                                            height: 90px;
                                            margin: 40px 0;
                                        }
                                    }
    
                                    .home-debate-bottom {
                                        display: flex;
                                        align-items: center;
                                        justify-content: space-between;
    
                                        .shadow {
                                            width: 100%;
                                            height: 35px;
                                        }
    
                                        .home-debate-stats {
                                            display: flex;
                                            align-items: center;
                                            justify-content: space-evenly;
                                            gap: 15px;
                                            height: 35px;
                                            border-radius: $normal-radius;
                                            padding: 0 10px;
                                            width: calc(100% - 70px);
    
                                            a {
                                                color: inherit;
                                                text-decoration: none;
                                                
                                                b {
                                                    font-weight: bold;
                                                }
                                            }
    
                                            p {
                                                color: inherit;
                                                
                                                b {
                                                    font-family: "Montserrat-Bold";
                                                }
                                            }
                                        }
    
                                        .home-debate-goto {
                                            border-radius: 50px;
                                            height: 35px;
                                            width: 35px;
                                            display: flex;
                                            align-items: center;
                                            justify-content: center;
                                            transition: transform 618ms cubic-bezier(0.075, 0.82, 0.165, 1);
                                            text-decoration: none;
    
                                            &:hover {
                                                transform: scale(0.8);
                                                cursor: pointer;
                                            }
                                        }
                                    }
                                }
                            }
    
                            .home-arg-wrapper {
                                display: block;
                                width: calc(35vw - 20px);
                                height: auto;
                                overflow-y: visible;
                                padding: 10px;
                                border-radius: $normal-radius;
                                text-decoration: none;

                                @media screen and (max-width: $mobile-bp) {
                                    border-radius: 0;
                                    background-color: transparent;
                                    box-shadow: none;
                                    border-left: none;
                                    border-right: none;
                                    border-bottom: none;
                                    padding: 20px 10px;
                                }
    
                                .home-arg {
                                    
                                    .home-arg-header {
                                        display: flex;
                                        justify-content: space-between;
                                        align-items: flex-start;
    
                                        .home-arg-header-pp-author-title {
                                            display: flex;
                                            justify-content: flex-start;
                                            align-items: center;
                                            gap: 10px;
    
                                            p {
                                                margin: 0;
    
                                                &:nth-child(1) {
                                                    font-family: "Montserrat-Bold";
                                                }
                                            }

                                            @media screen and (max-width: $small-bp) {
                                                align-items: flex-start;
                                            }
                                        }
    
                                        a {
                                            text-decoration: none;
                                        }
                                    }
    
                                    .home-arg-content {
                                        width: 100%;
    
                                        p {
                                            padding-left: 10px;
                                        }
                                    }
                                }
                            }

                            @media screen and (max-width: $mobile-bp) {
                                .mobile-shadow {
                                    margin-bottom: 20px;
                                }
                            }
                        }
                    }
    
                    @media screen and (max-width: $medium-bp) {
    
                        #home-body {
                            gap: 25px;
    
                            #home-left-side {
                                display: none;
                            }
    
                            #home-main {
                                width: 55vw;
                                max-width: 55vw;
    
                                .home-debate-wrapper, .home-arg-wrapper, #open-debate-btn {
                                    min-width: 55vw;
                                    width: 55vw;
                                    max-width: 55vw;
                                }
                            }
    
                            #home-right-side {
                                width: 32vw;
                            }
                        }
                    }
    
                    @media screen and (max-width: $small-bp) {
                        #home-body {
                            width: 100%;
                            gap: 0px;
    
                            .home-side-component-wrapper {
                                margin-bottom: 0px;
                            }

                            @media screen and (max-width: $mobile-bp) {
                                .home-side-component-wrapper {
                                    border-radius: 0;
                                    margin-top: 0;
                                    margin-bottom: 0px;
                                }
                            }
    
                            #home-left-side, #home-right-side {
                                display: none;
                            }
    
                            #home-main {
                                min-width: 100%;
    
                                #open-debate-btn {
                                    display: none;
                                }
    
                                #open-debate-floating {
                                    position: fixed;
                                    z-index: 1000;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    font-size: 20px;
                                    font-family: "Montserrat-Bold";
                                    bottom: 20px;
                                    left: 20px;
                                    width: 40px;
                                    height: 40px;
                                    border-radius: 20px;
                                    text-decoration: none;
                                }
    
                                .home-debate-wrapper, .home-arg-wrapper {
                                    width: calc(100% - 20px);
                                    max-width: 600px;
    
                                    @media screen and (max-width: $extraSmall-bp) {
    
                                        .home-debate-nb-args {
                                            display: none;
                                        }
                                    }
                                }
    
                                #small-inline-side-components {
                                    min-width: 100%;
                                    display: flex;
                                    align-items: flex-start;
                                    justify-content: space-evenly;
    
                                    .home-side-component-wrapper {
                                        width: calc(50% - 15px);
                                    }
    
                                    @media screen and (max-width: $extraSmall-bp) {
                                        flex-direction: column;
                                        gap: 20px;
    
                                        .home-side-component-wrapper {
                                            width: 100%;
                                        }
                                    }

                                    @media screen and (max-width: $mobile-bp) {
                                        margin-top: 5vh;
                                        margin-bottom: 20px;
                                        flex-direction: column;
                                        gap: 10px;
    
                                        .home-side-component-wrapper {
                                            border-radius: $normal-radius;
                                            width: 95%;
                                            margin-left: 2.5%;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            // Debate page styling
            #debate-header {
                margin-top: 30px;
                display: flex;
                flex-direction: column;
                align-items: center;

                #debate-stats {
                    display: flex;
                    justify-content: space-evenly;
                    align-items: center;
                    gap: 5vw;
                    margin-block: 3vh;
                    //background-color: #48DC69;

                    @media screen and (max-width: $mobile-bp) {
                        flex-direction: column;
                    }

                    #debate-pct-container {
                        display: flex;
                        align-items: center;
                        gap: 2vw;

                        .debate-pct {
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: space-evenly;
                            padding: 15px 0px;
                            min-width: 105px;
                            border-radius: $xl-radius;

                            p {
                                margin: 0px;
                                font-size: $small-size;
                            }

                            #pct {
                                font-size: $medium-size;
                                font-family: "Montserrat-Bold";
                            }

                            svg {
                                margin-bottom: 5px;
                            }
                        }
                    }

                    #debate-info-container {
                        display: flex;
                        align-items: center;
                        gap: 1.5vw;

                        @media screen and (max-width: $mobile-bp) {
                            gap: 5vw;
                        }
                        
                        .debate-info {
                            border-radius: $big-radius;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            gap: 3px;
                            padding: 10px 15px;
                            
                            a {
                                text-decoration: none;
                            }
                        }

                        #share-debate {
                            padding: 5px;

                            &:hover {
                                cursor: pointer;
                            }
                        }
                    }
                }

                #debate-about-container {
                    display: flex;
                    align-items: flex-end;
                    justify-content: center;
                    max-width: 900px;
                    width: 100%;
                    margin: 5vh;


                    #debate-about {
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        gap: 0px;

                        #debate-about-info {
                            display: flex;
                            align-items: center;
                            gap: 10px;
                        }

                        #debate-about-reason {
                            text-align: center;
                        }

                        #xs-args-side-comps {
                            width: 100%;
                            margin-top: 5vh;
                            margin-bottom: 0;
                            display: flex;
                            align-items: flex-start;
                            justify-content: center;
                            gap: 20px;

                            .home-side-component-wrapper {
                                margin-bottom: 0;
                                max-width: 250px;
                            }

                            @media screen and (max-width: $extraSmall-bp) {
                                flex-direction: column;
                                align-items: center;

                                .home-side-component-wrapper {
                                    max-height: 60px;
                                    
                                    .home-side-title {
                                        font-size: 15px;
                                    }

                                    .home-side-component {
                                        
                                        p {
                                            font-size: 13px;
                                        }
                                    }

                                    .vote-btns-wrapper {
                                        height: 100%;
                                        
                                        div {
                                            height: 35px;
                                        }
                                    }
                                }
                            }

                            @media screen and (max-width: $small-bp) {

                                .home-side-component-wrapper {
                                    //max-height: 60px;
                                    
                                    .home-side-title {
                                        font-size: 15px;
                                    }

                                    .home-side-component {
                                        
                                        p {
                                            font-size: 13px;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    #debate-argue-wrapper {
                        width: 20%;
                        display: flex;
                        justify-content: flex-end;

                        .btn {
                            margin: 0;
                            border-radius: $big-radius;
                        }
                    }
                }
            }

            #new-arg-container {
                display: none;
                justify-content: center;

                #new-arg {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    width: 100%;
                    height: 100px;
                    padding: 10px 0;
                    max-width: 900px;
                    border-radius: $small-radius;

                    #new-arg-box {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        width: 100%;
                        height: 100%;
                        padding: 0px 3vw;

                        span {
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: space-between;
                            gap: 10px;
                            height: 100%;

                            .btn, .no-bg {
                                margin: 0px;
                            }
                        }

                        #new-argument-content {
                            width: 55%;
                            align-items: flex-start;

                            textarea {
                                width: 95%;
                                height: 90%;
                                border-radius: $small-radius;
                                resize: none;
                                padding: 2px 5px;
                            }
                        }

                        #new-argument-type {
                            height: 100%;
                            align-items: flex-start;

                            div {
                                display: flex;
                                align-items: flex-end;
                                gap: 10px;
                                height: 90%;
                                
                                div {
                                    padding: 5px;
                                    border-radius: $small-radius;
                                }
                            }
                        }

                        #new-argument-btns {
                            max-height: 50px;

                            button {
                                border-radius: $big-radius;
                            }
                        }
                    }
                }
            }

            #args-and-right-components {
                width: 95%;
                display: flex;
                align-items: flex-start;
                justify-content: center;
                gap: 50px;

                @media screen and (max-width: $small-bp) {
                    width: 100%;
                    gap: 0px;
                }

                #arguments-container {
                    display: flex;
                    justify-content: center;
                    margin: 5vh 0;
                    margin-bottom: 20vh;
                    width: 70%;
                    max-width: 800px;
                    overflow-x: hidden;

                    @media screen and (max-width: $small-bp) {
                        width: 100%;
                    }
    
                    #arguments {
                        display: block;
                        width: 100%;
                        max-width: 700px;

                        #after-args-side-comps {
                            width: 100%;
                            display: flex;
                            align-items: flex-start;
                            justify-content: space-evenly;

                            .home-side-component-wrapper {
                                max-width: 250px;
                                margin-bottom: 0;
                            }
                        }

                        .xs-counter-icon {
                            width: min-content;
                            height: min-content;
                            margin-left: 7px;
                            margin-bottom: 0;
                            padding-bottom: 0;

                            svg {
                                margin-bottom: inherit;
                                padding-bottom: inherit;
                            }
                        }
                    }

                    .counter {

                        @media screen and (max-width: $small-bp) {
                            margin-top: 15px;
                        }
                    }
    
                    .argument, .counter {
                        margin-top: 30px;
                        width: 100%;

                        @media screen and (max-width: $mobile-bp) {
                            margin-left: 10px;
                        }
    
                        .argument-header-wrapper {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            margin-bottom: 5px;
    
                            .argument-header {
                                display: flex;
                                justify-content: flex-start;
                                align-items: center;
                                gap: 7px;
        
                                .argument-author-vote-when {
                                    margin-left: 5px;

                                    .argument-author {
                                        font-family: "Montserrat-Bold";
                                        margin: 0;
                                        padding: 0;
                                    }

                                    .argument-vote-when {
                                        display: flex;
                                        align-items: center;

                                        .argument-header-separator {
                                            width: 3px;
                                            height: 3px;
                                            border-radius: 2px;
                                            margin: 0 5px;
                                        }

                                        p {
                                            margin: 0;
                                            padding: 0;
                                        }
                                    }
                                }
                            }
                        }
    
                        .argument-content-wrapper {
                            display: flex;
                            align-items: flex-start;
                            justify-content: flex-start;
                            gap: 5px;
                            margin-left: 15px;
                            width: 100%;
                            margin-bottom: 15px;
    
                            .argument-content {
                                margin-left: 2px;
                                width: 100%;
        
                                p {
                                    margin-left: 15px;
                                    max-width: 95%;
                                }

                                @media screen and (max-width: $small-bp) {
                                    .argument-text {
                                        max-width: 85%;
                                        text-align: left;
                                    }
                                }
    
                                .counter {
                                    margin-left: 10px;
                                    width: calc(100% + 24px);
                                    overflow-x: hidden;
    
                                    .argument-header {
                                        
                                        p {
                                            margin-left: 0px;
                                        }
                                    }
    
                                    .argument-content-wrapper {
                                        width: calc(100% - 55px);

                                        .argument-content {
                                            width: 100%;
                                        }
                                    }
                                }
    
                                .argument-vote-score-wrapper {
                                    display: flex;
                                    align-items: center;
                                    justify-content: space-between;
                                    margin-left: 22px;
                                    padding: 8px 0;
                                    width: 100%;
                                    min-width: max-content;
    
                                    .argument-vote-score {
                                        display: flex;
                                        align-items: center;
                                        justify-content: flex-start;
                                        gap: 12px;
                                        
                                        p {
                                            margin: 0 0px;
                                        }

                                        span {
                                            display: flex;
                                            align-items: center;
                                            gap: 7px;
                                            margin-right: 6px;
                                            font-family: "Montserrat-medium";

                                            p {
                                                font-family: inherit;
                                            }
                                        }

                                        .signal:hover {
                                            cursor: pointer;
                                        }

                                        .argument-del {

                                            &:hover {
                                                cursor: pointer;
                                            }

                                            span {
                                                border-radius: 20px;
                                                margin-right: 3px;
                                                padding: 1px 4px;
                                                font-size: 10px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
    
                    .counter {
                        margin-top: 20px;
                    }
                }

                #debate-side-components-wrapper {
                    margin: 5vh 0;
                    width: 20%;
                    max-width: 300px;

                    .home-side-component-wrapper {
                        &:nth-child(1) {

                            &:hover {
                                cursor: pointer;
                            }
                        }
                    }
                }
            }

            #topic-debates-wrapper {
                width: 100%;
                margin-top: 10vh;

                @media screen and (max-width: $mobile-bp) {
                    width: 100%
                }
                
                #topic-debates {
                    width: 100%;
                    overflow-y: hidden;
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    #topic-title-wrapper {
                        width: 100%;
                        max-width: 620px;
                        margin-bottom: 5vh;

                        @media screen and (max-width: $mobile-bp) {
                            width: calc(100% - 20px);
                            max-width: calc(100% - 20px);
                        }

                        h1 {
                            text-align: left;
                            font-size: 20px;
                            font-family: "Montserrat-Bold";
                        }
                    }

                    .topic-debate-wrapper {
                        width: calc(100% - 20px);
                        margin-bottom: 20px;
                        max-width: 600px;
                        border-radius: $normal-radius;
                        padding: 10px;
                        text-decoration: none;

                        @media screen and (max-width: 650px) {
                            width: calc(100% - 20px);
                            max-width: calc(100% - 20px);
                            border-radius: 0;
                            border-left: none;
                            border-right: none;
                            margin-bottom: 0;

                            &:not(:nth-last-child(1)) {
                                border-bottom: none;
                            }
                        }

                        .topic-debate {
                            color: inherit;

                            a {
                                font-family: "Montserrat-bold";
                                font-size: 17px;
                                text-decoration: none;
                                color: inherit;
                            }

                            .border {
                                margin: 10px 0;
                            }

                            .debate-info-wrapper {
                                display: flex;
                                justify-content: flex-start;
                                align-items: center;
                                gap: 15px;

                                .debate-info {
                                    display: flex;
                                    align-items: center;
                                    gap: 5px;

                                    p {
                                        font-family: "Montserrat-semi";
                                    }
                                }
                            }
                        }
                    }
                }
            }

            #account-wrapper {
                width: 90%;
                padding: 8vh 0;

                #account-user-wrapper {
                    width: 100%;

                    #account-user {
                        width: 100%;
                        display: flex;
                        justify-content: flex-start;
                        align-items: flex-end;
                        gap: 20px;

                        @media screen and (max-width: $small-bp) {
                            flex-direction: column;
                            align-items: flex-start;
                        }

                        #pic-wrapper {
                            width: 15vw;
                            height: 15vw;
                            max-width: 150px;
                            max-height: 150px;
                            border-radius: 10vw;
                            display: flex;
                            justify-content: center;
                            align-items: center;

                            &:hover {
                                cursor: pointer;
                            }

                            .profile-pic-wrapper {
                                width: 100%;
                                height: 100%;
                                max-width: 150px;
                                max-height: 150px;
                                border-radius: 10vw;

                                image {
                                    width: 100%;
                                    height: 100%;
                                    max-width: 150px;
                                    max-height: 150px;
                                }

                                svg {
                                    width: 80%;
                                    aspect-ratio: 1;
                                }
                            }
                        }

                        #user {
                            width: 100%;

                            #uid {
                                
                                p {
                                    font-size: $big-title-size * 1.2;
                                    font-family: "Montserrat-Bold";
                                    margin-bottom: 20px;

                                    @media screen and (max-width: $extraSmall-bp) {
                                        font-size: $big-title-size * 0.5;
                                    }
                                }
                            }

                            .bg-light-gradient {
                                width: 100%;
                                min-width: 100px;
                                height: 5px;
                                border-radius: 3px;
                            }

                            #stats {
                                display: flex;
                                align-items: center;
                                justify-content: flex-start;
                                gap: 20px;

                                p {
                                    font-family: "Montserrat-semi";
                                    font-size: 15px;
                                    color: inherit;
                                }
                            }
                        }

                        #pic-input {
                            display: none;
                        }
                    }

                    #user-info-wrapper {
                        margin-top: 10vh;
                        border-radius: $normal-radius;
                        width: 100%;
                        height: max-content;
                        min-height: 200px;

                        #user-info {
                            padding: 10px;
                            width: calc(100% - 20px);

                            h3, p {
                                color: inherit;
                            }

                            h3 {
                                font-family: "Montserrat-semi";
                                margin-bottom: 15px;
                            }

                            #info-inline {
                                display: flex;
                                align-items: center;
                                gap: 20px;
                            }

                            button {
                                background-color: transparent;
                                border-radius: 3px;
                            }

                            #info-row-reverse {
                                display: flex;
                                width: 100%;
                                flex-direction: row-reverse;

                                button {
                                    border: none;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}


@keyframes opacity-animation-in {
    from { opacity: 0 }
    to { opacity: 1 }
}

@keyframes btn-hover {
    0% { margin-right: 0px; }
    50% { margin-right: 10px; }
    100% { margin-right: 0px; }
}

@keyframes modal-close {
    from { transform: rotate(0deg) }
    to { transform: rotate(360deg) }
}

@keyframes plus-rotation {
    0% { transform: rotate(0deg);}
    70% { transform: rotate(180deg);}
    100% { transform: rotate(90deg);}
}

@keyframes shadow-animation {
    0% {opacity: 1;}
    50% {opacity: 0.38;}
    100% {opacity: 1;}
}

@keyframes loading {

    0% {
        margin-left: 0%;
    }

    50% {
        margin-left: 80%;
    }
    
    100% {
        margin-left: 0%;
    }
}